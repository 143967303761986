<template>
  <ProductsList />
</template>

<script>
export default {
  components: {
    ProductsList: () => import('@/components/stock/products/ProductsList'),
  },
}
</script>